<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      persistent
      width="350"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          {{ textoMensaje }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      absolute
      touchless
      width="100%"
      permanent
    >
      <div
        class="top-main d-flex flex-column align-center justify-center"
      >
        <v-img
          src="assets/logo_la_n.png"
          max-width="250"
          @click="$router.push(
            { path: '/data' },
            () => {
              $emit('reload')
            },
          )"
        />
      </div>
      <v-card
        v-if="desk"
        color="white"
        height="90%"
        width="95%"
        class="mx-auto"
      >
        <iframe
          width="100%"
          height="100%"
          src="https://lookerstudio.google.com/embed/reporting/1873f0a9-2f58-4c4a-a89f-567620e40e7f/page/HsfHD"
          frameborder="0"
          style="border:0"
          allowfullscreen
        />
      </v-card>
      <v-card
        v-else
        color="white"
        height="78%"
        width="95%"
        class="mx-auto"
      >
        <iframe
          width="100%"
          height="100%"
          src="https://lookerstudio.google.com/embed/reporting/b05d1a6f-807f-4312-a46c-dbd10457fc43/page/HsfHD"
          frameborder="0"
          style="border:0"
          allowfullscreen
        />
      </v-card>
      <br>
      <v-row
        justify="center"
      >
        <v-btn
          color="primary"
          depressed
          @click="$router.push('/delito_detalle')"
        >
          DETALLE DE DELITOS
          <v-icon right>
            mdi-handcuffs
          </v-icon>
        </v-btn>
      </v-row>
      <br>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    components: {
    },

    data: () => ({
      desk: true,
      usuario: '',
      clave: '',
      carga: false,
      sedeCorteData: '',
      regla: [v => !!v || 'El campo es requerido'],
      imgFondo: '',
      imgLogo: '',
      error: false,
      textoMensaje: 'CARGANDO',
      textoError: '',
    }),
    created () {
      if (navigator.maxTouchPoints > 1) {
        this.desk = false
      }
      this.imgFondo = localStorage.getItem('imgFondo')
      this.imgLogo = localStorage.getItem('imgLogo')
    },
    mounted () {
      this.$store.commit('SET_VALOR', true)
    },
  }
</script>
